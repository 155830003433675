import { IconArrowBack, IconCross, IconX } from "@tabler/icons-react";
import { useNavigate, useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { Label } from "flowbite-react";
import { useEffect, useState } from "react";
import { api } from "@idecore/lib";
import { toast } from "react-toastify";
import { BeatLoader } from "react-spinners";

export default function OLTEditPage(){
    const { id } = useParams();
    const navigate = useNavigate()
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [name, setName] = useState('');
    const [label, setLabel] = useState('');
    const [host, setHost] = useState('');
    const [totalSlot, setTotalSlot] = useState('');
    const [lat, setLat] = useState('');
    const [long, setLong] = useState('');
    const [description, setDescription] = useState('');
    const [location, setLocation] = useState('');
    const [provinces, setProvinces] = useState('');
    const [province, setProvince] = useState('');
    const [regencies, setRegencies] = useState('');
    const [regency, setRegency] = useState('');
    const [districts, setDistricts] = useState('');
    const [district, setDistrict] = useState('');
    const [villages, setVillages] = useState('');
    const [village, setVillage] = useState('');

    const { data: locations, refetch: refetchLocation, isLoading: isLoadingLocation, isFetching: isFetchingLocation } = useQuery({
        queryKey: ["locations"],
        queryFn: async() => {
            try{
                const response = await api.location.lists()
                return response.data
            }catch(error: any){
                if(error.response.status === 401){
                    try{
                        localStorage.removeItem('token');
                        localStorage.removeItem('refresh_token');

                        const response = await api.auth.refresh_token({
                            "refresh_token": localStorage.getItem('refresh_token')
                        })

                        localStorage.setItem('token', response?.data?.data?.access_token);
                        localStorage.setItem('refresh_token', response?.data?.data?.refresh_token);

                        refetchLocation()
                    }catch(error:any){
                        console.error(error)

                        toast.error("Your session is expired, please login again.");
                        navigate("/login");
                    }
                }else{
                    console.error(error)
                }
            }
        }
    });

    useEffect(() => {
        const myNewAsyncMethod = async () => {
            // async request
            const olt = await api.device.OLTById(id)
    
            // logic after async request.
            if (olt) {
                setLocation(olt?.data?.data?.location_id)
                setName(olt?.data?.data?.olt_name)
                setLabel(olt?.data?.data?.olt_label)
                setLat(olt?.data?.data?.olt_latitude)
                setLong(olt?.data?.data?.olt_longitude)
                setHost(olt?.data?.data?.olt_host)
                setTotalSlot(olt?.data?.data?.olt_total_slot)
                setDescription(olt?.data?.data?.olt_description)
                setProvince(olt?.data?.data?.olt_province_id)
                setRegency(olt?.data?.data?.olt_regency_id)
                setDistrict(olt?.data?.data?.olt_district_id)
                setVillage(olt?.data?.data?.olt_village_id)
            }
        }
    
        // trigger new async function.
        myNewAsyncMethod();
    }, []);

    useEffect(() => {
        const myNewAsyncMethod = async () => {
            // async request.
            const provinces = await api.region.listProvinces();
    
            // logic after async request.
            if (provinces) {
                const rowProvinces = provinces?.data?.data.map((province:any) => (
                    <option key={province.id} value={province.id}>{province.name}</option>
                ))

                setProvinces(rowProvinces)
            }
        }
    
        // trigger new async function.
        myNewAsyncMethod();
    }, []); // <-- fetch once when component mounts
      
    useEffect(() => {
        const myNewAsyncMethod = async () => {
            // async request.
            const regencies = await api.region.listRegencies(province);
    
            // logic after async request.
            if (regencies) {
                const rowRegencies = regencies?.data?.data.map((regency:any) => (
                    <option key={regency.id} value={regency.id}>{regency.name}</option>
                ))

                setRegencies(rowRegencies)
            }
        }

        if (province != ''){
            // trigger new async function.
            myNewAsyncMethod();   
        }
    }, [province]);

    useEffect(() => {
        const myNewAsyncMethod = async () => {
            // async request.
            const districts = await api.region.listDistricts(regency);
    
            // logic after async request.
            if (districts) {
                const rowDistricts = districts?.data?.data.map((district:any) => (
                    <option key={district.id} value={district.id}>{district.name}</option>
                ))

                setDistricts(rowDistricts)
            }
        }
    
        if (regency != ''){
            // trigger new async function.
            myNewAsyncMethod();
        }
    }, [regency]);

    useEffect(() => {
        const myNewAsyncMethod = async () => {
            // async request.
            const villages = await api.region.listVillages(district);
    
            // logic after async request.
            if (villages) {
                const rowVillages = villages?.data?.data.map((village:any) => (
                    <option key={village.id} value={village.id}>{village.name}</option>
                ))

                setVillages(rowVillages)
            }
        }
    
        if (district != ''){
            // trigger new async function.
            myNewAsyncMethod();
        }
    }, [district]);

    const rowLocations = locations?.data.map((location:any) => (
        <option key={location.id} value={location.id}>{location.name}</option>
    ))

    const onSubmit = async () => {
        try{
            setIsSubmitting(true);

            const response = await api.device.updateOLT({
                "id": id,
                "location_id": Number(location),
                "latitude": lat,
                "longitude": long,
                "province_id": province,
                "regency_id": regency,
                "district_id": district,
                "village_id": village,
                "name": name,
                "label": label,
                "host": host,
                "total_slot": Number(totalSlot),
                "description": description
            })

            if(response.status == 401){
                toast.error("Invalid Email or Password")

                setIsSubmitting(false);
            }

            setIsSubmitting(false);
            
            navigate('/devices/olt')
            toast.success("Success");
        }catch(error: any){
            if(error.response.status === 401){
                toast.error(error.response.data.message, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                })
            }else if(error.response.status === 500){
                toast.error(error.response.data.message, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                })
            }

            setIsSubmitting(false);
        }
    }

    return(
        <>
            <nav className="mb-3 flex px-5 py-3 text-gray-700 border border-gray-200 rounded-lg bg-gray-50 dark:bg-gray-800 dark:border-gray-700" aria-label="Breadcrumb">
                <ol className="inline-flex items-center space-x-1 md:space-x-2 rtl:space-x-reverse">
                    <li className="inline-flex items-center">
                        <a href="#" className="inline-flex items-center text-sm font-medium text-gray-700 hover:text-blue-600 dark:text-gray-400 dark:hover:text-white">
                            <svg className="w-3 h-3 me-2.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                <path d="m19.707 9.293-2-2-7-7a1 1 0 0 0-1.414 0l-7 7-2 2a1 1 0 0 0 1.414 1.414L2 10.414V18a2 2 0 0 0 2 2h3a1 1 0 0 0 1-1v-4a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v4a1 1 0 0 0 1 1h3a2 2 0 0 0 2-2v-7.586l.293.293a1 1 0 0 0 1.414-1.414Z"/>
                            </svg>
                            Devices
                        </a>
                    </li>
                    <li className="inline-flex items-center">
                        <a href="#" className="inline-flex items-center text-sm font-medium text-gray-700 hover:text-blue-600 dark:text-gray-400 dark:hover:text-white">
                            <svg className="rtl:rotate-180  w-3 h-3 mx-1 text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 9 4-4-4-4"/>
                            </svg>
                            OLT
                        </a>
                    </li>
                    <li aria-current="page">
                        <div className="flex items-center">
                            <svg className="rtl:rotate-180  w-3 h-3 mx-1 text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 9 4-4-4-4"/>
                            </svg>
                            <span className="ms-1 text-sm font-medium text-gray-500 md:ms-2 dark:text-gray-400">Edit OLT</span>
                        </div>
                    </li>
                </ol>
            </nav>

            <div className="bg-white dark:bg-gray-800 relative shadow-md sm:rounded-lg overflow-hidden">
                <h2 className="p-4 mb-4 text-xl font-bold text-gray-900 dark:text-white">Edit OLT</h2>

                <div className="grid gap-4 mb-2 grid-cols-3 p-4">
                    <div className="col-span-2 sm:col-span-1">
                        <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Location</label>
                        <select id="location" value={location} onChange={(e) => setLocation(e.target.value)} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500">
                            <option >Select location</option>
                            { isLoadingLocation || isFetchingLocation
                                ? ''
                                : rowLocations
                            }
                        </select>
                    </div>
                    <div className="col-span-2 sm:col-span-1">
                        <div className="mb-2 block">
                            <Label htmlFor="name" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white" value="Name" />
                        </div>
                        <input type="text" name="name" id="name" value={name} onChange={(e) => setName(e.target.value)} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500" required />
                    </div>
                    <div className="col-span-2 sm:col-span-1">
                        <div className="mb-2 block">
                            <Label htmlFor="name" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white" value="Label" />
                        </div>
                        <input type="text" name="label" id="label" value={label} onChange={(e) => setLabel(e.target.value)} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500" required />
                    </div>
                </div>

                <div className="grid gap-4 mb-2 grid-cols-2 p-4">
                    <div className="col-span-2 sm:col-span-1">
                        <div className="mb-2 block">
                            <Label htmlFor="latitude" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white" value="Latitude" />
                        </div>
                        <input type="text" name="latitude" id="latitude" value={lat} onChange={(e) => setLat(e.target.value)} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500" required />
                    </div>
                    <div className="col-span-2 sm:col-span-1">
                        <div className="mb-2 block">
                            <Label htmlFor="longitude" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white" value="Longitude" />
                        </div>
                        <input type="text" name="longitude" id="longitude" value={long} onChange={(e) => setLong(e.target.value)} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500" required />
                    </div>
                </div>

                <div className="grid gap-4 mb-2 grid-cols-4 p-4">
                    <div className="col-span-2 sm:col-span-1">
                        <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Province</label>
                        <select id="province" value={province} onChange={(e) => {
                            setProvince(e.target.value);
                            setRegency('');
                            setDistrict('');
                            setVillage('');
                        }} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500">
                            <option >Select provinces</option>
                            { provinces }
                        </select>
                    </div>
                    {
                        (province != '') && (
                            <div className="col-span-2 sm:col-span-1">
                                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Regency</label>
                                <select id="regency" value={regency} onChange={(e) => {
                                    setRegency(e.target.value);
                                    setDistrict('');
                                    setVillage('');
                                }} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500">
                                    <option >Select regency</option>
                                    { regencies }
                                </select>
                            </div>
                        )
                    }
                    {
                        (regency != '') && (
                            <div className="col-span-2 sm:col-span-1">
                                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">District</label>
                                <select id="district" value={district} onChange={(e) => {
                                    setDistrict(e.target.value);
                                    setVillage('');
                                }} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500">
                                    <option >Select district</option>
                                    { districts }
                                </select>
                            </div>
                        )
                    }
                    {
                        (district != '') && (
                            <div className="col-span-2 sm:col-span-1">
                                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Village</label>
                                <select id="village" value={village} onChange={(e) => {
                                    setVillage(e.target.value);
                                }} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500">
                                    <option >Select villages</option>
                                    { villages }
                                </select>
                            </div>
                        )
                    }
                </div>

                <div className="grid gap-4 mb-2 grid-cols-3 p-4">
                    <div className="col-span-2 sm:col-span-1">
                        <div className="mb-2 block">
                            <Label htmlFor="host" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white" value="Host" />
                        </div>
                        <input type="text" name="host" id="host" value={host} onChange={(e) => setHost(e.target.value)} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500" required />
                    </div>
                    <div className="col-span-2 sm:col-span-1">
                        <div className="mb-2 block">
                            <Label htmlFor="totalSlot" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white" value="Total Slot" />
                        </div>
                        <select id="totalSlot" value={totalSlot} onChange={(e) => setTotalSlot(e.target.value)} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500">
                            <option >Select Slot</option>
                            <option value="2" >2 Slot</option>
                            <option value="16" >16 Slot</option>
                        </select>
                    </div>
                    <div className="col-span-2 sm:col-span-1">
                        <div className="mb-2 block">
                            <Label htmlFor="description" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white" value="Description" />
                        </div>
                        <input type="text" name="description" id="description" value={description} onChange={(e) => setDescription(e.target.value)} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500" required />
                    </div>
                </div>

                <div className="flex flex-col md:flex-row items-center justify-end space-y-3 md:space-y-0 md:space-x-4 p-4">
                    <div className="w-full md:w-auto flex flex-col md:flex-row space-y-2 md:space-y-0 items-stretch md:items-center justify-end md:space-x-3 flex-shrink-0">
                        <button 
                            type="button" 
                            className="flex items-center justify-center text-gray-900 bg-gray-300 hover:bg-gray-200 focus:ring-4 focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 dark:bg-primary-600 dark:hover:bg-primary-700 focus:outline-none dark:focus:ring-primary-800"
                            onClick={() => navigate("/devices/olt")}
                        >
                            <IconX className="h-3.5 w-3.5 mr-2" />
                            Cancel
                        </button>
                        <button onClick={onSubmit} className={"w-full text-white bg-blue-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"}>
                            { isSubmitting 
                                ?   <BeatLoader
                                        color="#FFFFFF" 
                                        size={8} 
                                    />
                                : 'Save Changes'}
                        </button>
                    </div>
                </div>
            </div>
        </>
    )
}