import { IconAdjustments, IconChartPie, IconChevronDown, IconChevronUp, IconDashboard, IconRouter, IconTableRow } from "@tabler/icons-react";
import { useState, FC, ComponentProps } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Sidebar } from "flowbite-react";

export default function SidebarL(){
    return (
        <Sidebar className="fixed top-0 left-0 z-40 w-64 h-screen pt-14">
            <Sidebar.Items>
                <Sidebar.ItemGroup>
                    <Link 
                        to="/dashboard"
                        className={`flex items-center p-2 text-base font-medium ${location.pathname === "/dashboard" ? `bg-slate-200 text-blue-700` : `text-gray-900` } rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group`}
                    >
                        <svg
                            aria-hidden="true"
                            className={`w-6 h-6 ${location.pathname === "/dashboard" ? `text-blue-700` : `text-gray-500` } transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white`}
                            fill="currentColor"
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path d="M2 10a8 8 0 018-8v8h8a8 8 0 11-16 0z"></path>
                            <path d="M12 2.252A8.014 8.014 0 0117.748 8H12V2.252z"></path>
                        </svg>
                        <span className="ml-3">Dashboard</span>
                    </Link>
                    <Sidebar.Collapse 
                        label="Master" 
                        icon={IconTableRow as FC<ComponentProps<"svg">>}
                        open={
                            location.pathname === "/master/locations" || 
                            location.pathname === "/master/users" 
                            ? true : false
                        }
                    >
                        <Link 
                            to="/master/locations"
                            className={`flex items-center p-2 pl-11 w-full text-base font-medium ${location.pathname === "/master/locations" ? `bg-slate-200 text-blue-700` : `text-gray-900` } rounded-lg transition duration-75 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700`}
                        >
                            Locations
                        </Link>
                        <Link 
                            to="/master/users"
                            className={`flex items-center p-2 pl-11 w-full text-base font-medium ${location.pathname === "/master/users" ? `bg-slate-200 text-blue-700` : `text-gray-900` } rounded-lg transition duration-75 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700`}
                        >
                            Users
                        </Link>
                    </Sidebar.Collapse>
                    <Sidebar.Collapse 
                        label="Devices" 
                        icon={IconRouter as FC<ComponentProps<"svg">>} 
                        open={
                            location.pathname === "/devices/olt" || 
                            location.pathname === "/devices/olt/create" || 
                            location.pathname === "/devices/odp" || 
                            location.pathname === "/devices/odc" || 
                            location.pathname === "/devices/ont" 
                            ? true : false
                        }
                    >
                        <Link
                            to="/devices/olt"
                            className={`flex items-center p-2 pl-11 w-full text-base font-medium ${location.pathname === "/devices/olt" || location.pathname === "/devices/olt/create" ? `bg-slate-200 text-blue-700` : `text-gray-900` } rounded-lg transition duration-75 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700`}
                        >
                            OLT
                        </Link>
                        <Link
                            to="/devices/odc"
                            className={`flex items-center p-2 pl-11 w-full text-base font-medium ${location.pathname === "/devices/odc" ? `bg-slate-200 text-blue-700` : `text-gray-900` } rounded-lg transition duration-75 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700`}
                        >
                            ODC
                        </Link>
                        <Link
                            to="/devices/odp"
                            className={`flex items-center p-2 pl-11 w-full text-base font-medium ${location.pathname === "/devices/odp" ? `bg-slate-200 text-blue-700` : `text-gray-900` } rounded-lg transition duration-75 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700`}
                        >
                            ODP
                        </Link>
                        <Link
                            to="/devices/ont"
                            className={`flex items-center p-2 pl-11 w-full text-base font-medium ${location.pathname === "/devices/ont" ? `bg-slate-200 text-blue-700` : `text-gray-900` } rounded-lg transition duration-75 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700`}
                        >
                            ONT
                        </Link>
                    </Sidebar.Collapse>
                </Sidebar.ItemGroup>
          </Sidebar.Items>
        </Sidebar>
    )
}