import { api } from "@idecore/lib";
import { useQuery } from "@tanstack/react-query"
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { BeatLoader } from "react-spinners";
import { Modal, Label } from "flowbite-react";
import { toast } from "react-toastify";
import { 
    IconDeviceFloppy, 
    IconEdit, 
    IconUserPlus, 
    IconTrash
} from "@tabler/icons-react";
import Swal from "sweetalert2";

export default function UsersPage(){
    const navigate = useNavigate()
    const [filterUser, setFilterUser] = useState([])
    const [length, setLength] = useState(0)
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(5)
    const [dataLoaded, setDataLoaded] = useState(false);
    const [openModalAdd, setOpenModalAdd] = useState(false);
    const [openModalEdit, setOpenModalEdit] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [id, setId] = useState('');
    const [location, setLocation] = useState('');
    const [role, setRole] = useState('');
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [selectedUser, setSelectedUser] = useState(null);
    const [editId, setEditId] = useState('');
    const [editLocation, setEditLocation] = useState('');
    const [editRole, setEditRole] = useState('');
    const [editName, setEditName] = useState('');
    const [editEmail, setEditEmail] = useState('');

    const indexOfLastItem = currentPage * itemsPerPage
    const indexOfFirstItem = indexOfLastItem - itemsPerPage

    const { data: users, refetch, isLoading, isFetching } = useQuery({
        queryKey: ["users"],
        queryFn: async() => {
            try{
                const response = await api.user.lists()
                setFilterUser(response.data.data)
                setLength(response.data.data.length)
                return response.data
            }catch(error: any){
                if(error.response.status === 401){
                    try{
                        localStorage.removeItem('token');
                        localStorage.removeItem('refresh_token');

                        const response = await api.auth.refresh_token({
                            "refresh_token": localStorage.getItem('refresh_token')
                        })

                        localStorage.setItem('token', response?.data?.data?.access_token);
                        localStorage.setItem('refresh_token', response?.data?.data?.refresh_token);

                        refetch()
                    }catch(error:any){
                        console.error(error)

                        toast.error("Your session is expired, please login again.");
                        navigate("/login");
                    }
                }else{
                    console.error(error)
                }
            }
        }
    });

    const { data: locations, refetch: refetchLocation, isLoading: isLoadingLocation, isFetching: isFetchingLocation } = useQuery({
        queryKey: ["locations"],
        queryFn: async() => {
            try{
                const response = await api.location.lists()
                return response.data
            }catch(error: any){
                if(error.response.status === 401){
                    try{
                        localStorage.removeItem('token');
                        localStorage.removeItem('refresh_token');

                        const response = await api.auth.refresh_token({
                            "refresh_token": localStorage.getItem('refresh_token')
                        })

                        localStorage.setItem('token', response?.data?.data?.access_token);
                        localStorage.setItem('refresh_token', response?.data?.data?.refresh_token);

                        refetch()
                    }catch(error:any){
                        console.error(error)

                        toast.error("Your session is expired, please login again.");
                        navigate("/login");
                    }
                }else{
                    console.error(error)
                }
            }
        }
    });

    const currentItems = filterUser.slice(indexOfFirstItem, indexOfLastItem)
    const paginate = (pageNumber: number) => setCurrentPage(pageNumber)

    const handleSearch = (value: any) => {
        if(value === "" || value === null){
            setFilterUser(users?.data);
        }else{
            const filtered = users?.data.filter(
                (user: any) =>
                    (
                        user && 
                        user.id &&
                        user.id.toLowerCase().includes(value.toLowerCase())
                    ) ||
                    (
                        user && 
                        user.role_name &&
                        user.role_name.toLowerCase().includes(value.toLowerCase())
                    ) ||
                    (
                        user && 
                        user.name &&
                        user.name.toLowerCase().includes(value.toLowerCase())
                    )||
                    (
                        user && 
                        user.email &&
                        user.email.toLowerCase().includes(value.toLowerCase())
                    )
            );
            setFilterUser(filtered);
            setLength(filtered.length);
        }
        setCurrentPage(1);
    }

    const handleEdit = async(user: any) => {
        setSelectedUser(user)
        setEditId(user.id)
        setEditLocation(user.location_id)
        setEditRole(user.role_id)
        setEditName(user.name)
        setEditEmail(user.email)
        setOpenModalEdit(true);
    }

    const handleDelete = async(id: any) => {
        Swal.fire({
          title: "Delete Account",
          text: "Are you sure to delete this Account?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#d33",
          cancelButtonColor: "#3085d6",
          confirmButtonText: "Yes, delete it!",
        }).then(async (result: any) => {
            if(result.isConfirmed){
                console.log(id);
                await toast.promise(
                    api.user.destroy({
                        "id": id 
                    }),
                    {
                        pending: "Delete Process ...",
                        success: "Deleted Successfully"
                    }
                )
            }

            refetch();
        })
    }

    useEffect(() => {
        if(!dataLoaded) return;
        setFilterUser(users);
    },[dataLoaded])
    
    const rows = currentItems.map((user: any) => (
        <tr key={user.id} className="border-b dark:border-gray-700">
            <th scope="row" className="px-4 py-3 font-medium text-gray-900 whitespace-nowrap dark:text-white">{user.id}</th>
            <td className="px-4 py-3">{user.location_name}</td>
            <td className="px-4 py-3">
                <span className="bg-blue-100 text-blue-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-blue-400 border border-blue-400">{user.role_name}</span>
            </td>
            <td className="px-4 py-3">{user.name}</td>
            <td className="px-4 py-3">{user.email}</td>
            <td className="px-4 py-3 flex items-center justify-start gap-2">
                <button 
                    data-tooltip-target="tooltip-edit" 
                    data-tooltip-trigger="hover" 
                    onClick={() => handleEdit(user)}
                    type="button" 
                    className="px-3 py-2 text-xs font-medium text-center text-yellow-700 bg-yellow-100 rounded-lg hover:bg-yellow-200 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                    <IconEdit />
                </button>
                <div id="tooltip-edit" role="tooltip" className="absolute z-10 invisible inline-block px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip dark:bg-gray-700">
                    Edit
                    <div className="tooltip-arrow" data-popper-arrow></div>
                </div>
                <button 
                    data-tooltip-target="tooltip-delete" 
                    data-tooltip-trigger="hover" 
                    onClick={() => handleDelete(user.id)}
                    type="button" 
                    className="px-3 py-2 text-xs font-medium text-center text-red-700 bg-red-100 rounded-lg hover:bg-red-200 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                    <IconTrash />
                </button>
                <div id="tooltip-delete" role="tooltip" className="absolute z-10 invisible inline-block px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip dark:bg-gray-700">
                    Delete
                    <div className="tooltip-arrow" data-popper-arrow></div>
                </div>
                {/* <button id={user.id} data-dropdown-toggle={user.id} className="inline-flex items-center p-0.5 text-sm font-medium text-center text-gray-500 hover:text-gray-800 rounded-lg focus:outline-none dark:text-gray-400 dark:hover:text-gray-100" type="button">
                    <svg className="w-5 h-5" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                        <path d="M6 10a2 2 0 11-4 0 2 2 0 014 0zM12 10a2 2 0 11-4 0 2 2 0 014 0zM16 12a2 2 0 100-4 2 2 0 000 4z" />
                    </svg>
                </button>
                <div id={user.id} className="hidden z-10 w-44 bg-white rounded divide-y divide-gray-100 shadow dark:bg-gray-700 dark:divide-gray-600">
                    <ul className="py-1 text-sm text-gray-700 dark:text-gray-200" aria-labelledby="apple-imac-20-dropdown-button">
                        <li>
                            <a href="#" className="block py-2 px-4 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Show</a>
                        </li>
                        <li>
                            <a href="#" className="block py-2 px-4 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Edit</a>
                        </li>
                    </ul>
                    <div className="py-1">
                        <a href="#" className="block py-2 px-4 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white">Delete</a>
                    </div>
                </div> */}
            </td>
        </tr>
    ))

    const rowLocations = locations.data.map((location:any) => (
        <option key={location.id} value={location.id}>{location.name}</option>
    ))

    const onSubmit = async () => {
        try{
            setIsSubmitting(true);

            const response = await api.user.store({
                "id": id,
                "location_id": Number(role),
                "role_id": Number(role),
                "name": name,
                "email": email,
                "password": password
            })

            if(response.status == 401){
                toast.error("Something went wrong")

                setIsSubmitting(false);
            }

            setIsSubmitting(false);

            refetch()
            
            toast.success("Success")
            setOpenModalAdd(false)
        }catch(error: any){
            if(error.response.status === 401){
                toast.error(error.response.data.message, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                })
            }else if(error.response.status === 500){
                toast.error(error.response.data.message, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                })
            }

            setIsSubmitting(false);
        }
    }

    const onSubmitEdit = async () => {
        try{
            setIsSubmitting(true);

            const response = await api.user.update({
                "id": editId,
                "location_id": Number(editLocation),
                "role_id": Number(editRole),
                "name": editName,
                "email": editEmail
            })

            if(response.status == 401){
                toast.error("Something went wrong")

                setIsSubmitting(false);
            }

            refetch()
            setIsSubmitting(false);

            toast.success("Updated Success")
            setOpenModalEdit(false)
        }catch(error: any){
            if(error.response.status === 401){
                toast.error(error.response.data.message, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                })
            }else if(error.response.status === 500){
                toast.error(error.response.data.message, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                })
            }

            setIsSubmitting(false);
        }
    }

    return(
        <>
            <nav className="mb-3 flex px-5 py-3 text-gray-700 border border-gray-200 rounded-lg bg-gray-50 dark:bg-gray-800 dark:border-gray-700" aria-label="Breadcrumb">
                <ol className="inline-flex items-center space-x-1 md:space-x-2 rtl:space-x-reverse">
                    <li className="inline-flex items-center">
                        <a href="#" className="inline-flex items-center text-sm font-medium text-gray-700 hover:text-blue-600 dark:text-gray-400 dark:hover:text-white">
                            <svg className="w-3 h-3 me-2.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                <path d="m19.707 9.293-2-2-7-7a1 1 0 0 0-1.414 0l-7 7-2 2a1 1 0 0 0 1.414 1.414L2 10.414V18a2 2 0 0 0 2 2h3a1 1 0 0 0 1-1v-4a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v4a1 1 0 0 0 1 1h3a2 2 0 0 0 2-2v-7.586l.293.293a1 1 0 0 0 1.414-1.414Z"/>
                            </svg>
                            Master
                        </a>
                    </li>
                    <li aria-current="page">
                        <div className="flex items-center">
                            <svg className="rtl:rotate-180  w-3 h-3 mx-1 text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 9 4-4-4-4"/>
                            </svg>
                            <span className="ms-1 text-sm font-medium text-gray-500 md:ms-2 dark:text-gray-400">Users</span>
                        </div>
                    </li>
                </ol>
            </nav>

            <div className="bg-white dark:bg-gray-800 relative shadow-md sm:rounded-lg overflow-hidden">
                <div className="flex flex-col md:flex-row items-center justify-between space-y-3 md:space-y-0 md:space-x-4 p-4">
                    <div className="w-full md:w-1/2">
                        <div className="flex items-center">
                            <label className="sr-only">Search</label>
                            <div className="relative w-full">
                                <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                                    <svg aria-hidden="true" className="w-5 h-5 text-gray-500 dark:text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clipRule="evenodd" />
                                    </svg>
                                </div>
                                <input type="text" id="simple-search" onChange={(e) => handleSearch(e.target.value)} autoComplete="off" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full pl-10 p-2 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500" placeholder="Search" required />
                            </div>
                        </div>
                    </div>
                    <div className="w-full md:w-auto flex flex-col md:flex-row space-y-2 md:space-y-0 items-stretch md:items-center justify-end md:space-x-3 flex-shrink-0">
                        <button 
                            type="button" 
                            className="flex items-center justify-center text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-primary-300 font-medium rounded-lg text-sm px-4 py-2 dark:bg-primary-600 dark:hover:bg-primary-700 focus:outline-none dark:focus:ring-primary-800"
                            onClick={() => setOpenModalAdd(true)}
                        >
                            <IconUserPlus className="h-3.5 w-3.5 mr-2" />
                            Add User
                        </button>
                    </div>
                    <Modal dismissible show={openModalAdd} size="lg" popup onClose={() => setOpenModalAdd(false)}>
                        <Modal.Header>
                            <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
                                <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
                                    Create New User
                                </h3>
                            </div>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="grid gap-4 mb-4 grid-cols-2">
                                <div className="col-span-2 sm:col-span-1">
                                    <div className="mb-2 block">
                                        <Label htmlFor="name" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white" value="ID" />
                                    </div>
                                    <input type="text" name="id" id="id" value={id} onChange={(e) => setId(e.target.value)} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500" required />
                                </div>
                                <div className="col-span-2 sm:col-span-1">
                                    <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Location</label>
                                    <select id="location" value={location} onChange={(e) => setLocation(e.target.value)} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500">
                                        <option value="">Select location</option>
                                        { isLoadingLocation || isFetchingLocation
                                            ? ''
                                            : rowLocations
                                        }
                                    </select>
                                </div>
                                <div className="col-span-2 sm:col-span-1">
                                    <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Role</label>
                                    <select id="role" value={role} onChange={(e) => setRole(e.target.value)} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500">
                                        <option >Select role</option>
                                        <option value="1">Superadmin</option>
                                        <option value="2">Kepala Teknisi</option>
                                        <option value="3">Teknisi</option>
                                    </select>
                                </div>
                                    <div className="col-span-2">
                                        <div className="mb-2 block">
                                            <Label htmlFor="name" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white" value="Name" />
                                        </div>
                                        <input type="text" name="name" id="name" value={name} onChange={(e) => setName(e.target.value)} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500" required />
                                    </div>
                                    <div className="col-span-2 sm:col-span-1">
                                        <div className="mb-2 block">
                                            <Label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white" value="Email" />
                                        </div>
                                        <input type="email" name="email" id="email" value={email} onChange={(e) => setEmail(e.target.value)} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500" required />
                                    </div>
                                    <div className="col-span-2 sm:col-span-1">
                                        <div className="mb-2 block">
                                            <Label htmlFor="password" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white" value="Password" />
                                        </div>
                                        <input type="password" name="password" id="password" value={password} onChange={(e) => setPassword(e.target.value)} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500" required />
                                    </div>
                            </div>

                            <div className="flex justify-end">
                                <button onClick={onSubmit} className="text-white inline-flex items-center bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                                    { 
                                        isSubmitting 
                                        ?   <BeatLoader
                                                color="#FFFFFF" 
                                                size={8} 
                                            />
                                        :   <>
                                                <IconDeviceFloppy className="me-1 -ms-1 w-5 h-5" /> 
                                                Save
                                            </>
                                    }
                                </button>
                            </div>
                        </Modal.Body>
                    </Modal>
                </div>
                <div className="overflow-x-auto">
                    <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                        <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                            <tr>
                                <th scope="col" className="px-4 py-3">ID</th>
                                <th scope="col" className="px-4 py-3">LOCATION</th>
                                <th scope="col" className="px-4 py-3">ROLE</th>
                                <th scope="col" className="px-4 py-3">NAME</th>
                                <th scope="col" className="px-4 py-3">EMAIL</th>
                                <th scope="col" className="px-4 py-3">
                                    <span className="sr-only">Actions</span>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                (isLoading || isFetching)
                                ?   <tr>
                                        <td colSpan={5}>
                                            <div className="flex justify-center">
                                                <BeatLoader 
                                                    color="#100CCD" 
                                                    size={8}
                                                />
                                            </div>
                                        </td>
                                    </tr>
                                :   rows.length > 0 
                                    ?   rows
                                    :   <tr className="border-b dark:border-gray-700">
                                            <td colSpan={5} className="text-center">Data Is Empty</td>
                                        </tr>
                            }
                        </tbody>
                    </table>
                </div>
                <nav className="flex flex-col md:flex-row justify-between items-start md:items-center space-y-3 md:space-y-0 p-4" aria-label="Table navigation">
                    <span className="text-sm font-normal text-gray-500 dark:text-gray-400">
                        Showing Page {currentPage} of {" "}
                        <span className="font-semibold text-gray-900 dark:text-white"> {Math.ceil(filterUser.length / itemsPerPage)}</span>
                        {" "} -
                        <span className="font-semibold dark:text-white"> Total {filterUser.length} Data</span>
                    </span>
                    <ul className="inline-flex items-stretch -space-x-px">
                        <li>
                            <button 
                                className={`flex items-center justify-center h-full py-1.5 px-3 ml-0 text-gray-500 bg-white rounded-l-lg border border-gray-300 ${currentPage === 1 ? '' : `hover:bg-gray-100 hover:text-gray-700 dark:hover:bg-gray-700 dark:hover:text-white`} dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400`}
                                onClick={() => paginate(currentPage - 1)}
                                disabled={currentPage === 1}
                            >
                                <span className="sr-only">Previous</span>
                                <svg className="w-5 h-5" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z" clipRule="evenodd" />
                                </svg>
                            </button>
                        </li>
                        <li>
                            <button 
                                className={`flex items-center justify-center h-full py-1.5 px-3 leading-tight text-gray-500 bg-white rounded-r-lg border border-gray-300 ${currentPage === Math.ceil(filterUser.length / itemsPerPage) || (Math.ceil(filterUser.length) === 0) ? '' : `hover:bg-gray-100 hover:text-gray-700 dark:hover:bg-gray-700 dark:hover:text-white`} dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400`}
                                onClick={() => paginate(currentPage + 1)}
                                disabled={currentPage === Math.ceil(filterUser.length / itemsPerPage) || (Math.ceil(filterUser.length) === 0)}
                            >
                                <span className="sr-only">Next</span>
                                <svg className="w-5 h-5" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd" />
                                </svg>
                            </button>
                        </li>
                    </ul>
                </nav>
            </div>

            <Modal dismissible show={openModalEdit} size="lg" popup onClose={() => setOpenModalEdit(false)}>
                <Modal.Header>
                    <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
                        <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
                            Edit User
                        </h3>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div className="grid gap-4 mb-4 grid-cols-2">
                        <div className="col-span-2 sm:col-span-1">
                            <div className="mb-2 block">
                                <Label htmlFor="name" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white" value="ID" />
                            </div>
                            <input type="text" name="id" id="id" value={editId} disabled onChange={(e) => setEditId(e.target.value)} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500" required />
                        </div>
                        <div className="col-span-2 sm:col-span-1">
                            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Location</label>
                            <select id="location" value={editLocation} onChange={(e) => setEditLocation(e.target.value)} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500">
                                <option >Select location</option>
                                { isLoadingLocation || isFetchingLocation
                                    ? ''
                                    : rowLocations
                                }
                            </select>
                        </div>
                        <div className="col-span-2 sm:col-span-1">
                            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Role</label>
                            <select id="role" value={editRole} onChange={(e) => setEditRole(e.target.value)} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500">
                                <option >Select role</option>
                                <option value="1">Superadmin</option>
                                <option value="2">Kepala Teknisi</option>
                                <option value="3">Teknisi</option>
                            </select>
                        </div>
                            <div className="col-span-2">
                                <div className="mb-2 block">
                                    <Label htmlFor="name" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white" value="Name" />
                                </div>
                                <input type="text" name="name" id="name" value={editName} onChange={(e) => setEditName(e.target.value)} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500" required />
                            </div>
                            <div className="col-span-2 sm:col-span-1">
                                <div className="mb-2 block">
                                    <Label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white" value="Email" />
                                </div>
                                <input type="email" name="email" id="email" value={editEmail} onChange={(e) => setEditEmail(e.target.value)} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500" required />
                            </div>
                    </div>

                    <div className="flex justify-end">
                        <button onClick={onSubmitEdit} className="text-white inline-flex items-center bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                            { 
                                isSubmitting
                                ?   <BeatLoader
                                        color="#FFFFFF" 
                                        size={8} 
                                    />
                                :   <>
                                        <IconDeviceFloppy className="me-1 -ms-1 w-5 h-5" /> 
                                        Save
                                    </>
                            }
                        </button>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}