import AxiosInstance from '@idecore/lib/axios';

export async function listOLT() {
    return AxiosInstance.get('/api/v1/devices/olt')
        .catch(function (error) {})
}

export async function OLTById(id: any) {
    return AxiosInstance.get(`/api/v1/devices/olt/${id}`)
        .catch(function (error) {})
}

export async function storeOLT(data: any) {
    console.log(data);
    return AxiosInstance.post('/api/v1/devices/olt', data)
        .catch(function (error) {
            return Promise.reject(error)
        })
}

export async function updateOLT(data: any) {
    console.log(data);
    return AxiosInstance.post(`/api/v1/devices/olt/${data.id}`, data)
        .catch(function (error) {
            return Promise.reject(error)
        })
}

export async function destroyOLT(param: any) {
    console.log(param);
    return AxiosInstance.delete(`/api/v1/devices/olt/${param.id}`)
        .catch(function (error) {
            return Promise.reject(error)
        })
}

export async function listOLTSlot(id: any) {
    return AxiosInstance.get(`/api/v1/devices/olt/${id}/slots`)
        .catch(function (error) {})
}

export async function updateOLTSlot(id: any, slotId: any, data: any) {
    console.log(data);
    return AxiosInstance.put(`/api/v1/devices/olt/${id}/slots/${slotId}`, data)
        .catch(function (error) {
            return Promise.reject(error)
        })
}