import AxiosInstance from '@idecore/lib/axios';

export async function listProvinces() {
    return AxiosInstance.get('/api/v1/provinces')
        .catch(function (error) {})}

export async function listRegencies(id?: any) {
    return AxiosInstance.get(`/api/v1/regencies/${id}`)
        .catch(function (error) {})}

export async function listDistricts(id?: any) {
    return AxiosInstance.get(`/api/v1/districts/${id}`)
        .catch(function (error) {})}

export async function listVillages(id?: any) {
    return AxiosInstance.get(`/api/v1/villages/${id}`)
        .catch(function (error) {})}