import { api } from "@idecore/lib";
import { IconArrowBack, IconDeviceFloppy, IconEdit, IconPlus } from "@tabler/icons-react";
import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { BeatLoader } from "react-spinners";
import { Modal, Label } from "flowbite-react";
import { toast } from "react-toastify";

export default function OLTSlotPage(){
    const { id } = useParams();
    const [filterOLTSlot, setFilterOLTSlot] = useState([])
    const [length, setLength] = useState(0)
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(10)
    const [dataLoaded, setDataLoaded] = useState(false);
    const navigate = useNavigate();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [selectedOLTSlot, setSelectedOLTSlot] = useState(null);
    const [oltName, setOLTName] = useState()
    const [oltLabel, setOLTLabel] = useState()
    const [openModalEdit, setOpenModalEdit] = useState(false);
    const [editSlotId, setEditSlotId] = useState('')
    const [editSlotLabel, setEditSlotLabel] = useState('')
    const [editSlotType, setEditSlotType] = useState('')

    const indexOfLastItem = currentPage * itemsPerPage
    const indexOfFirstItem = indexOfLastItem - itemsPerPage

    const { data: oltSlot, refetch: refetchSlot, isLoading: isLoadingSlot, isFetching: isFetchingSlot} = useQuery({
        queryKey: ["oltSlot"],
        queryFn: async() => {
            try{
                const response = await api.device.listOLTSlot(id)
                setFilterOLTSlot(response?.data?.data)
                setLength(response?.data?.data.length)
                return response
            }catch(error: any){
                if(error.response.status === 401){
                    const response = await api.auth.refresh_token({
                        "refresh_token": localStorage.getItem('refresh_token')
                    })

                    localStorage.removeItem('token');
                    localStorage.removeItem('refresh_token');
                    localStorage.setItem('token', response?.data?.data?.access_token);
                    localStorage.setItem('refresh_token', response?.data?.data?.refresh_token);
                }else{
                    console.error(error)
                }
            }
        }
    });

    const currentItems = filterOLTSlot.slice(indexOfFirstItem, indexOfLastItem)
    const paginate = (pageNumber: number) => setCurrentPage(pageNumber)

    useEffect(() => {
        const myNewAsyncMethod = async () => {
            // async request
            const olt = await api.device.OLTById(id)
    
            // logic after async request.
            if (olt) {
                setOLTName(olt?.data?.data?.olt_name)
                setOLTLabel(olt?.data?.data?.olt_label)
            }
        }
    
        // trigger new async function.
        myNewAsyncMethod();
    }, []);

    const handleEdit = async(oltSlot: any) => {
        setEditSlotId(oltSlot.id)
        setEditSlotLabel(oltSlot.slot_label)
        setEditSlotType(oltSlot.slot_type)
        setOpenModalEdit(true);
    }

    useEffect(() => {
        if(!dataLoaded) return;
        setFilterOLTSlot(oltSlot?.data?.data);
    },[dataLoaded])
    
    const rows = currentItems.map((oltSlot: any) => (
        <tr key={oltSlot.id} className="border-b dark:border-gray-700">
            <th scope="row" className="px-4 py-3 font-medium text-gray-900 whitespace-nowrap dark:text-white">{oltSlot.id}</th>
            <td className="px-4 py-3">{oltSlot.slot_label}</td>
            <td className="px-4 py-3">{oltSlot.slot_type}</td>
            <td className="px-4 py-3">{oltSlot.slot_total_port}</td>
            <td className="px-4 py-3 flex items-center justify-start gap-2">
                <button 
                    data-tooltip-target="tooltip-edit" 
                    data-tooltip-trigger="hover" 
                    onClick={() => handleEdit(oltSlot)}
                    type="button" 
                    className="px-3 py-2 text-xs font-medium text-center text-yellow-700 bg-yellow-100 rounded-lg hover:bg-yellow-200 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                    <IconEdit />
                </button>
                <div id="tooltip-edit" role="tooltip" className="absolute z-10 invisible inline-block px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip dark:bg-gray-700">
                    Edit
                    <div className="tooltip-arrow" data-popper-arrow></div>
                </div>
            </td>
        </tr>
    ))

    const onSubmitEdit = async () => {
        try{
            setIsSubmitting(true);

            const response = await api.device.updateOLTSlot(id,editSlotId,{
                "slot_label": editSlotLabel,
                "slot_type": editSlotType,
            })

            if(response.status == 401){
                toast.error("Something went wrong")

                setIsSubmitting(false);
            }

            refetchSlot()
            setIsSubmitting(false);

            toast.success("Updated Success")
            setOpenModalEdit(false)
        }catch(error: any){
            if(error.response.status === 401){
                toast.error(error.response.data.message, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                })
            }else if(error.response.status === 500){
                toast.error(error.response.data.message, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                })
            }

            setIsSubmitting(false);
        }
    }

    return(
        <>
            <nav className="mb-3 flex px-5 py-3 text-gray-700 border border-gray-200 rounded-lg bg-gray-50 dark:bg-gray-800 dark:border-gray-700" aria-label="Breadcrumb">
                <ol className="inline-flex items-center space-x-1 md:space-x-2 rtl:space-x-reverse">
                    <li className="inline-flex items-center">
                        <a href="#" className="inline-flex items-center text-sm font-medium text-gray-700 hover:text-blue-600 dark:text-gray-400 dark:hover:text-white">
                            <svg className="w-3 h-3 me-2.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                <path d="m19.707 9.293-2-2-7-7a1 1 0 0 0-1.414 0l-7 7-2 2a1 1 0 0 0 1.414 1.414L2 10.414V18a2 2 0 0 0 2 2h3a1 1 0 0 0 1-1v-4a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v4a1 1 0 0 0 1 1h3a2 2 0 0 0 2-2v-7.586l.293.293a1 1 0 0 0 1.414-1.414Z"/>
                            </svg>
                            Devices
                        </a>
                    </li>
                    <li className="inline-flex items-center">
                        <a href="#" className="inline-flex items-center text-sm font-medium text-gray-700 hover:text-blue-600 dark:text-gray-400 dark:hover:text-white">
                            <svg className="rtl:rotate-180  w-3 h-3 mx-1 text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 9 4-4-4-4"/>
                            </svg>
                            OLT
                        </a>
                    </li>
                    <li aria-current="page">
                        <div className="flex items-center">
                            <svg className="rtl:rotate-180  w-3 h-3 mx-1 text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 9 4-4-4-4"/>
                            </svg>
                            <span className="ms-1 text-sm font-medium text-gray-500 md:ms-2 dark:text-gray-400">Slot OLT</span>
                        </div>
                    </li>
                </ol>
            </nav>

            <div className="bg-white dark:bg-gray-800 relative shadow-md sm:rounded-lg overflow-hidden">
                <div className="flex flex-col md:flex-row items-center justify-between space-y-3 md:space-y-0 md:space-x-4 p-4">
                    <div className="w-full md:w-auto flex flex-col md:flex-row space-y-2 md:space-y-0 items-stretch md:items-center justify-end md:space-x-3 flex-shrink-0">
                        <button 
                            type="button" 
                            className="flex items-center justify-center text-gray-900 bg-gray-300 hover:bg-gray-200 focus:ring-4 focus:ring-primary-300 font-medium rounded-lg text-sm px-4 py-2 dark:bg-primary-600 dark:hover:bg-primary-700 focus:outline-none dark:focus:ring-primary-800"
                            onClick={() => navigate("/devices/olt")}
                        >
                            <IconArrowBack className="h-3.5 w-3.5 mr-2" />
                            Back to OLT
                        </button>
                    </div>
                </div> 

                <div className="grid gap-4 grid-cols-4 mr-4">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div className="mb-3 bg-blue-200 dark:bg-blue-800 relative sm:rounded-lg overflow-hidden">
                        <tr>
                            <td className="px-2 py-1">OLT NAME</td>
                            <td>:</td>
                            <td className="px-2 py-1">{ oltName }</td>
                        </tr>
                        <tr>
                            <td className="px-2 py-1">OLT LABEL</td>
                            <td>:</td>
                            <td className="px-2 py-1">{ oltLabel }</td>
                        </tr>
                    </div>
                </div>

                <div className="overflow-x-auto">
                    <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                        <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                            <tr>
                                <th scope="col" className="px-4 py-3">ID</th>
                                <th scope="col" className="px-4 py-3">LABEL</th>
                                <th scope="col" className="px-4 py-3">TYPE</th>
                                <th scope="col" className="px-4 py-3">TOTAL PORT</th>
                                <th scope="col" className="px-4 py-3">
                                    <span className="sr-only">Actions</span>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                (isLoadingSlot || isFetchingSlot)
                                ?   <tr>
                                        <td colSpan={5}>
                                            <div className="flex justify-center">
                                                <BeatLoader 
                                                    color="#100CCD" 
                                                    size={8}
                                                />
                                            </div>
                                        </td>
                                    </tr>
                                :   rows.length > 0 
                                    ?   rows
                                    :   <tr className="border-b dark:border-gray-700">
                                            <td colSpan={5} className="text-center">Data Is Empty</td>
                                        </tr>
                            }
                        </tbody>
                    </table>
                </div>
                <nav className="flex flex-col md:flex-row justify-between items-start md:items-center space-y-3 md:space-y-0 p-4" aria-label="Table navigation">
                    <span className="text-sm font-normal text-gray-500 dark:text-gray-400">
                        Showing Page {currentPage} of {" "}
                        <span className="font-semibold text-gray-900 dark:text-white"> {Math.ceil(filterOLTSlot.length / itemsPerPage)}</span>
                        {" "} -
                        <span className="font-semibold dark:text-white"> Total {filterOLTSlot.length} Data</span>
                    </span>
                    <ul className="inline-flex items-stretch -space-x-px">
                        <li>
                            <button 
                                className={`flex items-center justify-center h-full py-1.5 px-3 ml-0 text-gray-500 bg-white rounded-l-lg border border-gray-300 ${currentPage === 1 ? '' : `hover:bg-gray-100 hover:text-gray-700 dark:hover:bg-gray-700 dark:hover:text-white`} dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400`}
                                onClick={() => paginate(currentPage - 1)}
                                disabled={currentPage === 1}
                            >
                                <span className="sr-only">Previous</span>
                                <svg className="w-5 h-5" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z" clipRule="evenodd" />
                                </svg>
                            </button>
                        </li>
                        <li>
                            <button 
                                className={`flex items-center justify-center h-full py-1.5 px-3 leading-tight text-gray-500 bg-white rounded-r-lg border border-gray-300 ${currentPage === Math.ceil(filterOLTSlot.length / itemsPerPage) || (Math.ceil(filterOLTSlot.length) === 0) ? '' : `hover:bg-gray-100 hover:text-gray-700 dark:hover:bg-gray-700 dark:hover:text-white`} dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400`}
                                onClick={() => paginate(currentPage + 1)}
                                disabled={currentPage === Math.ceil(filterOLTSlot.length / itemsPerPage) || (Math.ceil(filterOLTSlot.length) === 0)}
                            >
                                <span className="sr-only">Next</span>
                                <svg className="w-5 h-5" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd" />
                                </svg>
                            </button>
                        </li>
                    </ul>
                </nav>
            </div>

            <Modal dismissible show={openModalEdit} size="lg" popup onClose={() => setOpenModalEdit(false)}>
                <Modal.Header>
                    <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
                        <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
                            Edit OLT Slot
                        </h3>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div className="grid gap-4 mb-4 grid-cols-2">
                        <div className="col-span-2 sm:col-span-1">
                            <div className="mb-2 block">
                                <Label htmlFor="label" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white" value="Slot Label" />
                            </div>
                            <input type="text" name="label" id="label" value={editSlotLabel} onChange={(e) => setEditSlotLabel(e.target.value)} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500" required />
                        </div>
                        <div className="col-span-2 sm:col-span-1">
                            <div className="mb-2 block">
                                <Label htmlFor="type" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white" value="Slot Type" />
                            </div>
                            <select id="type" value={editSlotType} onChange={(e) => setEditSlotType(e.target.value)} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500" required>
                                <option value="">Select Slot Type</option>
                                <option value="GTGO">GTGO - 8 Port</option>
                                <option value="GTGH">GTGH - 16 Port</option>
                            </select>
                        </div>
                    </div>

                    <div className="flex justify-end">
                        <button onClick={onSubmitEdit} className="text-white inline-flex items-center bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                            { 
                                isSubmitting
                                ?   <BeatLoader
                                        color="#FFFFFF" 
                                        size={8} 
                                    />
                                :   <>
                                        <IconDeviceFloppy className="me-1 -ms-1 w-5 h-5" /> 
                                        Save
                                    </>
                            }
                        </button>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}