import Axios from 'axios';
import { API_SECRET, BASE_URL } from './constants';

export const getToken = () => localStorage.getItem("token")
  ? localStorage.getItem("token")
  : null;

export const getAuthorization = () => `Bearer ${getToken()}`

let AxiosInstance = Axios.create({
    baseURL: BASE_URL,
    withCredentials: false,
    headers: {
			"Access-Control-Allow-Origin": "*",
			"Access-Control-Allow-Headers": "X-Secret-Key",
      'X-Requested-With': 'XMLHttpRequest',
      'Authorization': getAuthorization(),
      'X-Secret-Key': API_SECRET,
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
});

AxiosInstance.interceptors.request.use(function (config){
  config.headers["Authorization"] = "Bearer " + localStorage.getItem("token")
  return config
})

export default AxiosInstance