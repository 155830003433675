import { Outlet, useNavigate } from "react-router-dom";
import Header from "./includes/header";
import SidebarL from "./includes/sidebar";
import { useEffect } from "react";
import { toast } from "react-toastify";
import { useQuery } from "@tanstack/react-query"
import { api } from "@idecore/lib";

export default function LayoutDashboard(){
    const navigate = useNavigate()

    useEffect(() => {
        const token = localStorage.getItem("token");

        if(!token){
            navigate("/login");
            toast.error("Please login again.");
        } 
    })

    // useEffect(() => {
    //     const fetchMe = async() => {
    //         try{
    //             console.log("INI TOKEN FETCH ME " + localStorage.getItem('token'))
    //             const response = await api.auth.me()
    //             console.log(response.data.data.email)
    //             SetName(response.data.data.name)
    //             SetEmail(response.data.data.email)
    //         }catch(error: any){
    //             console.error(error)

    //             if(error.response.status == 401){
    //                 console.log("ERROR 401 NI")
    //                 localStorage.removeItem("token");
    //                 localStorage.removeItem("refresh_token");
    //                 navigate("/login");
    //                 toast.error("Your session is expired, please login again.");
    //             }
    //         }
    //     }
    //     console.log("RUNNING")
    //     fetchMe()
    // },[])

    
    const { data: me, refetch, isLoading } = useQuery({
        queryKey: ["me"],
        queryFn: async() => {
            try{
                const response = await api.auth.me()

                return response.data
            }catch(error: any){
                console.log(error)
            }
        }
    });

    return (
        <div className="bg-slate-100 dark:bg-gray-900">
            <Header 
                name={me?.data?.name}
                email={me?.data?.email}
            />
            <SidebarL />
            <main className="p-4 min-h-screen md:ml-64 pt-20">
                <Outlet />
            </main>
        </div>
    )
}