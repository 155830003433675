import { api } from "@idecore/lib";
import { IconCards, IconEdit, IconInfoCircle, IconPlus, IconTrash } from "@tabler/icons-react";
import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { BeatLoader } from "react-spinners";
import { Modal, Label } from "flowbite-react";
import { toast } from "react-toastify";
import { MapContainer, Marker, Popup, TileLayer } from "react-leaflet";
import 'leaflet/dist/leaflet.css';
import Swal from "sweetalert2";
import OLTMarker from "@idecore/assets/icons/OLT.svg";
import { Icon } from 'leaflet'

export default function OLTPage(){
    const [filterOLT, setFilterOLT] = useState([])
    const [length, setLength] = useState(0)
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(10)
    const [dataLoaded, setDataLoaded] = useState(false);
    const navigate = useNavigate();
    const [selectedOLT, setSelectedOLT] = useState(null);
    const [openModalInfo, setOpenModalInfo] = useState(false);
    const [infoId, setInfoId] = useState('');
    const [infoLocation, setInfoLocation] = useState('');
    const [infoName, setInfoName] = useState('');
    const [infoLabel, setInfoLabel] = useState('');
    const [infoHost, setInfoHost] = useState('');
    const [infoLat, setInfoLat] = useState('');
    const [infoLong, setInfoLong] = useState('');
    const [infoProvinceName, setInfoProvinceName] = useState('');
    const [infoRegencyName, setInfoRegencyName] = useState('');
    const [infoDistrictName, setInfoDistrictName] = useState('');
    const [infoVillageName, setInfoVillageName] = useState('');
    const [infoTotalSlot, setInfoTotalSlot] = useState('');
    const [infoTotalPort, setInfoTotalPort] = useState('');
    const [infoDescription, setInfoDescription] = useState('');

    const indexOfLastItem = currentPage * itemsPerPage
    const indexOfFirstItem = indexOfLastItem - itemsPerPage

    const currentItems = filterOLT.slice(indexOfFirstItem, indexOfLastItem)
    const paginate = (pageNumber: number) => setCurrentPage(pageNumber)
    
    const { data: olt, refetch, isLoading, isFetching } = useQuery({
        queryKey: ["olt"],
        queryFn: async() => {
            try{
                const response = await api.device.listOLT()
                setFilterOLT(response?.data?.data)
                setLength(response?.data?.data.length)
                return response
            }catch(error: any){
                if(error.response.status === 401){
                    const response = await api.auth.refresh_token({
                        "refresh_token": localStorage.getItem('refresh_token')
                    })

                    localStorage.removeItem('token');
                    localStorage.removeItem('refresh_token');
                    localStorage.setItem('token', response?.data?.data?.access_token);
                    localStorage.setItem('refresh_token', response?.data?.data?.refresh_token);

                    refetch()
                }else{
                    console.error(error)
                }
            }
        }
    });

    const handleSearch = (value: any) => {
        if(value === "" || value === null){
            setFilterOLT(olt?.data);
        }else{
            const filtered = olt?.data.filter(
                (item: any) =>
                    (
                        item && 
                        item.id &&
                        item.id.toLowerCase().includes(value.toLowerCase())
                    ) ||
                    (
                        item && 
                        item.name &&
                        item.name.toLowerCase().includes(value.toLowerCase())
                    ) ||
                    (
                        item && 
                        item.label &&
                        item.label.toLowerCase().includes(value.toLowerCase())
                    )
            );
            setFilterOLT(filtered);
            setLength(filtered.length);
        }
        setCurrentPage(1);
    }

    const handleInfo = async(olt: any) => {
        console.log(olt);
        setSelectedOLT(olt)
        setInfoId(olt.id)
        setInfoLocation(olt.location_name)
        setInfoName(olt.olt_name)
        setInfoLabel(olt.olt_label)
        setInfoHost(olt.olt_host)
        setInfoLat(olt.olt_latitude)
        setInfoLong(olt.olt_longitude)
        setInfoProvinceName(olt.olt_province_name)
        setInfoRegencyName(olt.olt_regency_name)
        setInfoDistrictName(olt.olt_district_name)
        setInfoVillageName(olt.olt_village_name)
        setInfoTotalSlot(olt.olt_total_slot)
        setInfoTotalPort(olt.olt_total_port)
        setInfoDescription(olt.olt_description)
        setOpenModalInfo(true);
    }

    const handleDelete = async(id: any) => {
        Swal.fire({
          title: "Delete Account",
          text: "Are you sure to delete this Account?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#d33",
          cancelButtonColor: "#3085d6",
          confirmButtonText: "Yes, delete it!",
        }).then(async (result: any) => {
            if(result.isConfirmed){
                await toast.promise(
                    api.device.destroyOLT({
                        "id": id 
                    }),
                    {
                        pending: "Delete Process ...",
                        success: "Deleted Successfully"
                    }
                )
            }

            refetch();
        })
    }

    useEffect(() => {
        if(!dataLoaded) return;
        setFilterOLT(olt?.data);
    },[dataLoaded])

    const rows = currentItems.map((olt: any) => (
        <tr key={olt.id} className="border-b dark:border-gray-700">
            <th scope="row" className="px-4 py-3 font-medium text-gray-900 whitespace-nowrap dark:text-white">{olt.id}</th>
            <td className="px-4 py-3">
                <span className="bg-blue-100 text-blue-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-blue-400 border border-blue-400">{olt.location_name}</span>
            </td>
            <td className="px-4 py-3">{olt.olt_name}</td>
            <td className="px-4 py-3">{olt.olt_label}</td>
            <td className="px-4 py-3">{olt.olt_host}</td>
            <td className="px-4 py-3">{olt.olt_total_slot}</td>
            <td className="px-4 py-3">{olt.olt_total_port}</td>
            <td className="px-4 py-3 flex items-center justify-start gap-2">
                <button 
                    data-tooltip-target="tooltip-edit" 
                    data-tooltip-trigger="hover" 
                    onClick={() => handleInfo(olt)}
                    type="button" 
                    className="px-3 py-2 text-xs font-medium text-center text-gray-700 bg-gray-100 rounded-lg hover:bg-gray-200 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                    <IconInfoCircle />
                </button>
                <button 
                    data-tooltip-target="tooltip-edit" 
                    data-tooltip-trigger="hover" 
                    onClick={() => navigate(`/devices/olt/${olt.id}/slots`)}
                    type="button" 
                    className="px-3 py-2 text-xs font-medium text-center text-blue-700 bg-blue-100 rounded-lg hover:bg-blue-200 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                    <IconCards />
                </button>
                <button 
                    data-tooltip-target="tooltip-edit" 
                    data-tooltip-trigger="hover" 
                    onClick={() => navigate(`/devices/olt/edit/${olt.id}`)}
                    type="button" 
                    className="px-3 py-2 text-xs font-medium text-center text-yellow-700 bg-yellow-100 rounded-lg hover:bg-yellow-200 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                    <IconEdit />
                </button>
                <div id="tooltip-edit" role="tooltip" className="absolute z-10 invisible inline-block px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip dark:bg-gray-700">
                    Edit
                    <div className="tooltip-arrow" data-popper-arrow></div>
                </div>
                <button 
                    data-tooltip-target="tooltip-delete" 
                    data-tooltip-trigger="hover" 
                    onClick={() => handleDelete(olt.id)}
                    type="button" 
                    className="px-3 py-2 text-xs font-medium text-center text-red-700 bg-red-100 rounded-lg hover:bg-red-200 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                    <IconTrash />
                </button>
                <div id="tooltip-delete" role="tooltip" className="absolute z-10 invisible inline-block px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip dark:bg-gray-700">
                    Delete
                    <div className="tooltip-arrow" data-popper-arrow></div>
                </div>
            </td>
        </tr>
    ))

    const { data: locations, refetch: refetchLocation, isLoading: isLoadingLocation, isFetching: isFetchingLocation } = useQuery({
        queryKey: ["locations"],
        queryFn: async() => {
            try{
                const response = await api.location.lists()
                return response.data
            }catch(error: any){
                if(error.response.status === 401){
                    try{
                        localStorage.removeItem('token');
                        localStorage.removeItem('refresh_token');

                        const response = await api.auth.refresh_token({
                            "refresh_token": localStorage.getItem('refresh_token')
                        })

                        localStorage.setItem('token', response?.data?.data?.access_token);
                        localStorage.setItem('refresh_token', response?.data?.data?.refresh_token);

                        refetch()
                    }catch(error:any){
                        console.error(error)

                        toast.error("Your session is expired, please login again.");
                        navigate("/login");
                    }
                }else{
                    console.error(error)
                }
            }
        }
    });

    const OLTIcon = new Icon({
        iconUrl: OLTMarker,
        iconSize: [32,32]
    })

    return(
        <>
            <nav className="mb-3 flex px-5 py-3 text-gray-700 border border-gray-200 rounded-lg bg-gray-50 dark:bg-gray-800 dark:border-gray-700" aria-label="Breadcrumb">
                <ol className="inline-flex items-center space-x-1 md:space-x-2 rtl:space-x-reverse">
                    <li className="inline-flex items-center">
                        <a href="#" className="inline-flex items-center text-sm font-medium text-gray-700 hover:text-blue-600 dark:text-gray-400 dark:hover:text-white">
                            <svg className="w-3 h-3 me-2.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                <path d="m19.707 9.293-2-2-7-7a1 1 0 0 0-1.414 0l-7 7-2 2a1 1 0 0 0 1.414 1.414L2 10.414V18a2 2 0 0 0 2 2h3a1 1 0 0 0 1-1v-4a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v4a1 1 0 0 0 1 1h3a2 2 0 0 0 2-2v-7.586l.293.293a1 1 0 0 0 1.414-1.414Z"/>
                            </svg>
                            Devices
                        </a>
                    </li>
                    <li aria-current="page">
                        <div className="flex items-center">
                            <svg className="rtl:rotate-180  w-3 h-3 mx-1 text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 9 4-4-4-4"/>
                            </svg>
                            <span className="ms-1 text-sm font-medium text-gray-500 md:ms-2 dark:text-gray-400">OLT</span>
                        </div>
                    </li>
                </ol>
            </nav>

            <div className="bg-white dark:bg-gray-800 relative shadow-md sm:rounded-lg overflow-hidden">
                <div className="flex flex-col md:flex-row items-center justify-between space-y-3 md:space-y-0 md:space-x-4 p-4">
                    <div className="w-full md:w-1/2">
                        <div className="flex items-center">
                            <label className="sr-only">Search</label>
                            <div className="relative w-full">
                                <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                                    <svg aria-hidden="true" className="w-5 h-5 text-gray-500 dark:text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clipRule="evenodd" />
                                    </svg>
                                </div>
                                <input type="text" id="simple-search" onChange={(e) => handleSearch(e.target.value)} autoComplete="off" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full pl-10 p-2 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500" placeholder="Search" required />
                            </div>
                        </div>
                    </div>
                    <div className="w-full md:w-auto flex flex-col md:flex-row space-y-2 md:space-y-0 items-stretch md:items-center justify-end md:space-x-3 flex-shrink-0">
                        <button 
                            type="button" 
                            className="flex items-center justify-center text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-primary-300 font-medium rounded-lg text-sm px-4 py-2 dark:bg-primary-600 dark:hover:bg-primary-700 focus:outline-none dark:focus:ring-primary-800"
                            onClick={() => navigate("/devices/olt/create")}
                        >
                            <IconPlus className="h-3.5 w-3.5 mr-2" />
                            Add OLT
                        </button>
                    </div>
                </div>

                <div className="overflow-x-auto">
                    <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                        <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                            <tr>
                                <th scope="col" className="px-4 py-3">ID</th>
                                <th scope="col" className="px-4 py-3">LOCATION</th>
                                <th scope="col" className="px-4 py-3">NAME</th>
                                <th scope="col" className="px-4 py-3">LABEL</th>
                                <th scope="col" className="px-4 py-3">HOST</th>
                                <th scope="col" className="px-4 py-3">TOTAL SLOT</th>
                                <th scope="col" className="px-4 py-3">TOTAL PORT</th>
                                <th scope="col" className="px-4 py-3">
                                    <span className="sr-only">Actions</span>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                (isLoading || isFetching)
                                ?   <tr>
                                        <td colSpan={8}>
                                            <div className="flex justify-center">
                                                <BeatLoader 
                                                    color="#100CCD" 
                                                    size={8}
                                                />
                                            </div>
                                        </td>
                                    </tr>
                                :   rows.length > 0 
                                    ?   rows
                                    :   <tr className="border-b dark:border-gray-700">
                                            <td colSpan={8} className="text-center">Data Is Empty</td>
                                        </tr>
                            }
                        </tbody>
                    </table>
                </div>
                <nav className="flex flex-col md:flex-row justify-between items-start md:items-center space-y-3 md:space-y-0 p-4" aria-label="Table navigation">
                    <span className="text-sm font-normal text-gray-500 dark:text-gray-400">
                        Showing Page {currentPage} of {" "}
                        <span className="font-semibold text-gray-900 dark:text-white"> {Math.ceil(filterOLT.length / itemsPerPage)}</span>
                        {" "} -
                        <span className="font-semibold dark:text-white"> Total {filterOLT.length} Data</span>
                    </span>
                    <ul className="inline-flex items-stretch -space-x-px">
                        <li>
                            <button 
                                className={`flex items-center justify-center h-full py-1.5 px-3 ml-0 text-gray-500 bg-white rounded-l-lg border border-gray-300 ${currentPage === 1 ? '' : `hover:bg-gray-100 hover:text-gray-700 dark:hover:bg-gray-700 dark:hover:text-white`} dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400`}
                                onClick={() => paginate(currentPage - 1)}
                                disabled={currentPage === 1}
                            >
                                <span className="sr-only">Previous</span>
                                <svg className="w-5 h-5" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z" clipRule="evenodd" />
                                </svg>
                            </button>
                        </li>
                        <li>
                            <button 
                                className={`flex items-center justify-center h-full py-1.5 px-3 leading-tight text-gray-500 bg-white rounded-r-lg border border-gray-300 ${currentPage === Math.ceil(filterOLT.length / itemsPerPage) || (Math.ceil(filterOLT.length) === 0) ? '' : `hover:bg-gray-100 hover:text-gray-700 dark:hover:bg-gray-700 dark:hover:text-white`} dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400`}
                                onClick={() => paginate(currentPage + 1)}
                                disabled={currentPage === Math.ceil(filterOLT.length / itemsPerPage) || (Math.ceil(filterOLT.length) === 0)}
                            >
                                <span className="sr-only">Next</span>
                                <svg className="w-5 h-5" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd" />
                                </svg>
                            </button>
                        </li>
                    </ul>
                </nav>
            </div>

            <Modal dismissible show={openModalInfo} size="2xl" popup onClose={() => setOpenModalInfo(false)}>
                <Modal.Header>
                    <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
                        <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
                            OLT Information
                        </h3>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div className="grid gap-4 mb-4 grid-cols-2">
                        <div className="col-span-2 sm:col-span-1">
                            <div className="mb-2 block">
                                <Label htmlFor="id" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white" value="ID" />
                            </div>
                            <input type="text" value={infoId} disabled className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500" required />
                        </div>
                        <div className="col-span-2 sm:col-span-1">
                            <div className="mb-2 block">
                                <Label htmlFor="location" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white" value="Location" />
                            </div>
                            <input type="text" value={infoLocation} disabled className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500" required />
                        </div>
                    </div>
                    <div className="grid gap-4 mb-4 grid-cols-3">
                        <div className="col-span-2 sm:col-span-1">
                            <div className="mb-2 block">
                                <Label htmlFor="name" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white" value="Name" />
                            </div>
                            <input type="text" value={infoName} disabled className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500" required />
                        </div>
                        <div className="col-span-2 sm:col-span-1">
                            <div className="mb-2 block">
                                <Label htmlFor="label" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white" value="Label" />
                            </div>
                            <input type="text" value={infoLabel} disabled className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500" required />
                        </div>
                        <div className="col-span-2 sm:col-span-1">
                            <div className="mb-2 block">
                                <Label htmlFor="host" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white" value="Host" />
                            </div>
                            <input type="text" value={infoHost} disabled className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500" required />
                        </div>
                    </div>
                    <div className="col-span-2 mb-4 sm:col-span-1">
                        <div className="mb-2 block">
                            <Label htmlFor="description" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white" value="Description" />
                        </div>
                        <input type="text" value={infoDescription} disabled className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500" required />
                    </div>
                    <div className="grid gap-4 mb-4 grid-cols-6">
                        <div className="col-span-2 sm:col-span-1">
                            <div className="mb-2 block">
                                <Label htmlFor="total_slot" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white" value="Total Slot" />
                            </div>
                            <input type="text" value={infoTotalSlot} disabled className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500" required />
                        </div>
                        <div className="col-span-2 sm:col-span-1">
                            <div className="mb-2 block">
                                <Label htmlFor="total_port" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white" value="Total Port" />
                            </div>
                            <input type="text" value={infoTotalPort} disabled className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500" required />
                        </div>
                    </div>

                    <div className="grid gap-4 mb-4 grid-cols-2">
                        <div className="col-span-2 sm:col-span-1">
                            <div className="mb-2 block">
                                <Label htmlFor="lat" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white" value="Latitude" />
                            </div>
                            <input type="text" value={infoLat} disabled className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500" required />
                        </div>
                        <div className="col-span-2 sm:col-span-1">
                            <div className="mb-2 block">
                                <Label htmlFor="long" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white" value="Longitude" />
                            </div>
                            <input type="text" value={infoLong} disabled className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500" required />
                        </div>
                    </div>

                    <div className="grid gap-4 mb-4 grid-cols-4">
                        <div className="col-span-2 sm:col-span-1">
                            <div className="mb-2 block">
                                <Label htmlFor="prov" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white" value="Province" />
                            </div>
                            <input type="text" value={infoProvinceName} disabled className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500" required />
                        </div>
                        <div className="col-span-2 sm:col-span-1">
                            <div className="mb-2 block">
                                <Label htmlFor="reg" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white" value="Regency" />
                            </div>
                            <input type="text" value={infoRegencyName} disabled className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500" required />
                        </div>
                        <div className="col-span-2 sm:col-span-1">
                            <div className="mb-2 block">
                                <Label htmlFor="district" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white" value="District" />
                            </div>
                            <input type="text" value={infoDistrictName} disabled className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500" required />
                        </div>
                        <div className="col-span-2 sm:col-span-1">
                            <div className="mb-2 block">
                                <Label htmlFor="village" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white" value="Village" />
                            </div>
                            <input type="text" value={infoVillageName} disabled className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500" required />
                        </div>
                    </div>

                    <MapContainer center={[Number(infoLat),Number(infoLong)]} zoom={100} scrollWheelZoom={false}
                        style={{ height:"400px",backgroundColor:"red",marginTop:"80px", marginBottom:'90px'}}
                    >
                        <TileLayer
                            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        />
                        <Marker position={[Number(infoLat),Number(infoLong)]} icon={OLTIcon}>
                            <Popup>
                                {infoLabel}
                            </Popup>
                        </Marker>
                    </MapContainer>
                </Modal.Body>
            </Modal>
        </>
    )
}