import AxiosInstance from '@idecore/lib/axios';

export async function login(data: any) {
    return AxiosInstance.post('/api/v1/auth/login', data)
        .catch(function (error) {
            // if (error.response) {
            //     // The request was made and the server responded with a status code
            //     // that falls out of the range of 2xx
            //     console.log(error.response.data);
            //     console.log(error.response.status);
            //     console.log(error.response.headers);
            // } else if (error.request) {
            //     // The request was made but no response was received
            //     // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            //     // http.ClientRequest in node.js
            //     console.log(error.request);
            // } else {
            //     // Something happened in setting up the request that triggered an Error
            //     console.log('Error', error.message);
            // }

            return Promise.reject(error)
        });
}

export async function me() {
    return AxiosInstance.get('/api/v1/me')
        .catch(function (error) {
            // if (error.response) {
            //     // The request was made and the server responded with a status code
            //     // that falls out of the range of 2xx
            //     console.log(error.response.data);
            //     console.log(error.response.status);
            //     console.log(error.response.headers);
            // } else if (error.request) {
            //     // The request was made but no response was received
            //     // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            //     // http.ClientRequest in node.js
            //     console.log(error.request);
            // } else {
            //     // Something happened in setting up the request that triggered an Error
            //     console.log('Error', error.message);
            // }
            // console.log(error.config);

            return Promise.reject(error)
        });
}

export async function refresh_token(data: any) {
    return AxiosInstance.post('/api/v1/auth/token/refresh', data)
        .catch(function (error) {
            return Promise.reject(error)
        });
}